import React from 'react';

export const ConfirmationOverlay = ({ item, onConfirm, onCancel }) => {
  return (
    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p>Are you sure you want to delete this item?</p>
        <div style={{ marginTop: '10px' }}>
          <button onClick={() => onConfirm(item)} className='inline-flex rounded-md border p-2 mr-4 text-sm font-medium text-white bg-color3 focus:ring-4 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in'>Confirm</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};