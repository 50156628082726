import React, { useEffect, useState } from 'react';
import link from "../../../images/link.svg";

const NewsComponent = () => {
  const [news, setNews] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      const apiKey = process.env.REACT_APP_NEWS_API_KEY;
      const apiUrl = `https://newsapi.org/v2/top-headlines?country=us&apiKey=${apiKey}`;

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setNews(data.articles);
      } catch (error) {
        console.error('There was a problem fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-4">Latest News</h1>
      <div className="w-3/5 max-w-2xl">
        {news.map((article, index) => (
          <div key={index} className="border border-gray-300 rounded-md p-4 mb-4">
            <h2 className="text-xl font-semibold mb-2">{article.title}</h2>
            <p className="text-gray-600 mb-2">{article.description}</p>
            <a
              href={article.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-500 hover:underline"
            >
              Read more
              <img src={link} alt="link" className="w-4 h-4 ml-1" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsComponent;
