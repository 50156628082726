import React, { useState, useEffect } from "react";
import axios from "axios";

const PendingInvitations = () => {
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchPendingInvitations = async () => {
  //     try {
  //       const response = await axios.get("/api/connections/pending");
  //       setPendingInvitations(response.data);
  //     } catch (error) {
  //       console.error("Error fetching pending invitations:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPendingInvitations();
  // }, []);

  // const handleAccept = async (invitationId) => {
  //   try {
  //     await axios.post(`/api/connections/pending/${invitationId}/accept`);
  //     // Reload pending invitations
  //     fetchPendingInvitations();
  //   } catch (error) {
  //     console.error("Error accepting invitation:", error);
  //   }
  // };

  // const handleReject = async (invitationId) => {
  //   try {
  //     await axios.post(`/api/connections/pending/${invitationId}/reject`);
  //     // Reload pending invitations
  //     fetchPendingInvitations();
  //   } catch (error) {
  //     console.error("Error rejecting invitation:", error);
  //   }
  // };

  return (
    <div>
      <h1>Pending Invitations</h1>
      {/* {loading ? (
        <div>Loading...</div>
      ) : pendingInvitations.length > 0 ? (
        <ul>
          {pendingInvitations.map((invitation) => (
            <li key={invitation._id}>
              <div>{invitation.senderName}</div>
              <button onClick={() => handleAccept(invitation._id)}>
                Accept
              </button>
              <button onClick={() => handleReject(invitation._id)}>
                Reject
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <div>No pending invitations</div>
      )} */}
    </div>
  );
};

export default PendingInvitations;
