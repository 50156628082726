import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

import "./UserList.css"; // Import custom CSS file for styling

const formatName = (firstName, lastName) => {
  const formattedFirstName =
    firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  const formattedLastName =
    lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
  return `${formattedFirstName} ${formattedLastName}`;
};

export default function UserList() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch user data when the component mounts
    const fetchUsers = async () => {
      try {
        const response = await fetch("http://localhost:3000/getAllUserDetails");
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        setUsers(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div className="user-list-container">
      {loading ? (
        <div className="loading-text">Loading...</div>
      ) : users.length > 0 ? (
        <div className="user-card-container">
          <h1 className="mb-4">All Users</h1>
          {users.map((user) => (
            <div key={user.email} className="user-card">
              <div className="user-icon">
                <PersonIcon className="mr-1" />
              </div>
              <div className="user-details">
                <h2 className="user-name">
                  <NavLink
                    className="border-black transition duration-150 ease-out hover:ease-in hover:text-custom_hov h-full flex items-center"
                    to={`/profile/${user.fname.toLowerCase()}`}
                  >
                    {formatName(user.fname, user.lname)}
                  </NavLink>
                </h2>
                <p className="user-info">
                  {user.companyName ? user.companyName : user.graduationCollge}
                </p>
              </div>
              <button className="connect-button">Connect</button>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-users-text">No users found</div>
      )}
    </div>
  );
}
