import React, { useState, useEffect } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Groups2Icon from "@mui/icons-material/Groups2";
import SchoolIcon from "@mui/icons-material/School";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import PersonIcon from "@mui/icons-material/Person";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import QuizIcon from "@mui/icons-material/Quiz";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import SearchDialog from "./Body/Search/SearchDialog";

export default function Nav() {
  const [login, setLogin] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false); // State variable for search dialog visibility

  // Function to handle click event on the search icon
  const handleClickSearch = () => {
    setShowSearchDialog(true); // Show the search dialog when the search icon is clicked
  };

  useEffect(() => {
    if (sessionStorage.getItem("email")) {
      setLogin(true);
    }
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem("user");
    sessionStorage.removeItem("email");
  };

  return (
    <>
      <div className="home top-50">
        <div className="__nav basis-1/12 shrink-0 flex drop-shadow-lg padding-50 z-50 bg-white">
          <div className="sidebar">
            <h1 className="flex items-center h-full justify-center font-bold text-black text-2xl">
              <span className="text-color3">Org</span>Connect
            </h1>
          </div>
          <div className="__body">
            <ul className="h-full ml-20 flex items-center gap-x-5 text-sm text-color4 font-normal ">
              <NavLink
                className="border-black transition duration-150 ease-out hover:ease-in hover:text-custom_hov h-full flex items-center"
                to="/app/home"
              >
                <li className=" px-3 flex flex-col items-center">
                  <HomeRoundedIcon className="mr-1" />
                  Home
                </li>
              </NavLink>
              <NavLink
                className="border-black transition duration-150 ease-out hover:ease-in hover:text-custom_hov h-full flex items-center"
                to="/app/group"
              >
                <li className=" px-3 flex flex-col items-center">
                  <Groups2Icon className="mr-1" />
                  Group
                </li>
              </NavLink>
              <NavLink
                className="border-black transition duration-150 ease-out hover:ease-in hover:text-custom_hov h-full flex items-center"
                to="/app/learn"
              >
                <li className=" px-3 flex flex-col items-center">
                  <SchoolIcon className="mr-1" /> Learn
                </li>
              </NavLink>
              <NavLink
                className="border-black transition duration-150 ease-out hover:ease-in hover:text-custom_hov h-full flex items-center"
                to="/app/message"
              >
                <li className="px-3 flex flex-col items-center">
                  <QuizIcon className="mr-1" />
                  Ask AI
                </li>
              </NavLink>
              <NavLink
                className="border-black transition duration-150 ease-out hover:ease-in hover:text-custom_hov h-full flex items-center"
                to="/app/chat"
              >
                <li className=" px-3 flex flex-col items-center">
                  <ChatIcon className="mr-1" />
                  Chat
                </li>
              </NavLink>
              <NavLink
                className="border-black transition duration-150 ease-out hover:ease-in hover:text-custom_hov h-full flex items-center"
                to="/app/profile"
              >
                <li className=" px-3 flex flex-col items-center">
                  <PersonIcon className="mr-1" />
                  Profile
                </li>
              </NavLink>
              <NavLink
                className="border-black transition duration-150 ease-out hover:ease-in hover:text-custom_hov h-full flex items-center"
                to="/app/search"
              >
                <li className=" px-3 flex flex-col items-center">
                  <SearchIcon className="mr-1" />
                  Search
                </li>
              </NavLink>
            </ul>
          </div>
          <div className=" flex justify-around ml-5 items-center text-color3">
            <Link
              to="/"
              className=" mx-3 hover:text-custom_hov font-normal"
              onClick={handleLogOut}
            >
              {login ? <LogoutRoundedIcon /> : <LoginRoundedIcon />}{" "}
              {login ? "Logout" : "Login"}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
