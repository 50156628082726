import React, { useState } from "react";
import axios from "axios";

const SendConnectionRequest = ({ senderId, receiverId }) => {
  const [loading, setLoading] = useState(false);

  const sendRequest = async () => {
    setLoading(true);
    try {
      await axios.post("/api/connections/send", { senderId, receiverId });
      // Handle success, e.g., show success message
    } catch (error) {
      // Handle error, e.g., show error message
      console.error("Error sending connection request:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button onClick={sendRequest} disabled={loading}>
      {loading ? "Sending..." : "Send Connection Request"}
    </button>
  );
};

export default SendConnectionRequest;
