import prf1 from "../../../images/prf2.svg";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Buffer } from "buffer";
export default function ProfileSidebar() {
  const [userData, setUserData] = useState("")
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const email = sessionStorage.getItem('email')
    const getUserData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/getUser?email=${email}`,
        {
          method: "GET",
        }
      );

      const data = await res.json();
      setUserData(data);
    }

    const getUserImage = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/getUserImage?email=${email}`,
        {
          method: "GET",
        }
      );

      const data = await res.json();
      if (data) {
        const base64String = Buffer.from(data.data).toString("base64");
        setImageSrc(`data:image/jpeg;base64,${base64String}`);
      }
    }

    getUserData();
    getUserImage();
  }, [])

  return (
    <div className="sidebar flex p-4 sticky w-auto top-0 ">
      <div
        className="profile h-fit flex flex-col items-center rounded-lg bg-color3 shadow-lg drop-shadow-lg p-4"
        style={{ width: "3010px" }}
      >
        <img
          src={imageSrc ? imageSrc : prf1}
          alt="profile"
          className="sidebar_profile border-custom_white mt-4 bg-white shadow-md rounded-full"
        />
        <div className="flex w-full flex-col items-center my-4 ">
          <h1 className="text-xl text-white font-semibold">
            {userData ? userData[0]?.firstName.charAt(0).toUpperCase() + userData[0]?.firstName.slice(1) : ""} {userData ? userData[0]?.lastName : ""}
          </h1>
          {/* <p className="mt-2 text-custom_black text-base ">
              {" "}
              {userData ? userData[0]?.city : ""},{" "}
              {userData ? userData[0]?.state : ""}
            </p> */}
          <p className="p-2 italic font-main text-sm text-white mb-4 text-center ">
            {userData ? userData[0]?.intro : ""}
          </p>
        </div>

        {/* New hyperlinks for Group and Connections */}
        <div className="flex flex-col items-center ">
          <Link to="/app/group" className=" border border-white rounded-md px-5 py-1 text-white font-semibold text-sm hover:bg-custom_use mb-2">Group</Link>
          <Link to="/app/Connect" className="border border-white text-white rounded-md px-5 py-1 font-semibold mt-3 text-sm hover:bg-custom_use">People</Link>
        </div>
      </div>
    </div>
  );
}
