import prf1 from "../../images/prf2.svg";
import { useState, useEffect } from "react";
import { Buffer } from "buffer";
import { useNavigate } from "react-router";
// import { PDFDownloadLink} from "@react-pdf/renderer";
import { Resume } from "./ResumeTemplate";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
// import { Document } from "@react-pdf/renderer";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationOverlay } from "./ConfirmationOverlay";

export default function Profile() {
  const [userData, setUserData] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [papers, setPapers] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [paperInputs, setPaperInputs] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [experienceInputs, setExperienceInputs] = useState(false);
  const [showCertificateInputFields, setShowCertificateInputFields] =
    useState(false);
  const [showPatentInputFields, setShowPatentInputFields] = useState(false);
  const [patentData, setPatentData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteAction, setDeleteAction] = useState(null);
  const navi = useNavigate();

  useEffect(() => {
    const email = sessionStorage.getItem("email");
    const getUserData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/getUser?email=${email}`,
        {
          method: "GET",
        }
      );

      const data = await res.json();
      setUserData(data);
    };

    const getUserImage = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/getUserImage?email=${email}`,
        {
          method: "GET",
        }
      );

      const data = await res.json();
      if (data) {
        const base64String = Buffer.from(data.data).toString("base64");
        setImageSrc(`data:image/jpeg;base64,${base64String}`);
      }
    };
    getUserData();
    getUserImage();
  }, []);

  const handleProfileEdit = () => {
    navi("/EduDetails");
  };

  const generatePdfDocument = async () => {
    console.log(userData[0]);
    const blob = await pdf(
      <Resume data={userData[0]} image={imageSrc} title="My PDF" />
    ).toBlob();
    saveAs(blob, userData[0].firstName);
  };

  const handleSubmitPaper = (event) => {
    event.preventDefault();
    setPapers([
      ...papers,
      {
        name: event.target.name.value,
        url: event.target.url.value,
        dropdown1: event.target.dropdown1.value,
        dropdown2: event.target.dropdown2.value,
      },
    ]);
    event.target.name.value = "";
    event.target.url.value = "";
    event.target.dropdown1.value = "defaultOption1";
    event.target.dropdown2.value = "defaultOption2";
  };

  const handleDeletePaper = (name) => {
    // Find the index of the paper to delete
    const indexToDelete = papers.findIndex((paper) => paper.name === name);

    // Create a new array without the paper to delete
    if (indexToDelete !== -1) {
      // Check if the paper exists
      const newData = papers.filter((_, index) => index !== indexToDelete);
      setPapers(newData);
      setShowConfirmation(false);
    }
  };

  const handleSubmitExperience = (event) => {
    event.preventDefault();

    const startDate = event.target.elements.startDate.value;
    const endDate = event.target.elements.endDate.value;
    const organization = event.target.elements.organization.value;
    const position = event.target.elements.position.value;

    const newExperience = { startDate, endDate, organization, position };

    setExperiences([...experiences, newExperience]);

    event.target.reset();
    setExperienceInputs(false);
  };

  const handleDeleteExperience = (experienceToDelete) => {
    const updatedExperiences = experiences.filter(
      (experience) => experience !== experienceToDelete
    );
    setExperiences(updatedExperiences);
    setShowConfirmation(false);
  };

  const handleSubmitCertificate = (event) => {
    event.preventDefault();

    setCertificateData([
      ...certificateData,
      {
        name: event.target.certificateName.value,
        url: event.target.certificateUrl.value,
      },
    ]);
    event.target.reset();

    setShowCertificateInputFields(false);
  };

  const handleDeleteCertificate = (certificateToDelete) => {
    const updateCertificate = certificateData.filter(
      (certificate) => certificate !== certificateToDelete
    );
    setCertificateData(updateCertificate);
    setShowConfirmation(false);
  };

  const handleSubmitPatent = (event) => {
    event.preventDefault();
    const currentYear = new Date().getFullYear();
    if (event.target.publishYear.value > currentYear) {
      alert("The patent year cannot be more than the current year.");
      return;
    }
    setPatentData([
      ...patentData,
      {
        name: event.target.patentName.value,
        id: event.target.patentID.value,
        year: event.target.publishYear.value,
        url: event.target.patentURL.value,
      },
    ]);
    event.target.reset();
  };

  const handleDeletePatent = (patentToDelete) => {
    const updatePatent = patentData.filter(
      (patent) => patent !== patentToDelete
    );
    setPatentData(updatePatent);
    setShowConfirmation(false);
  };

  const requestDelete = (item, action) => {
    setShowConfirmation(true);
    setItemToDelete(item);
    setDeleteAction(() => action);
  };

  const handleDelete = (item) => {
    if (deleteAction) {
      deleteAction(item);
    }
    setShowConfirmation(false);
  };
  const stringList = userData[0]?.skills;
  const listArray = stringList ? stringList.split(', ') : [];

  return (
    <>
      <div className="main_body bg-custom_bg basis-11/12 flex justify-center">
        <div className="__body p-4 flex bg-color2 m-4 rounded-lg shadow-lg drop-shadow-lg">
          <div className="flex w-2/5 flex-col p-5">
            <img
              src={imageSrc ? imageSrc : prf1}
              alt="profile"
              className="w-4/6 border-custom_white bg-white shadow-md m-4 rounded-full"
            />
            <h1 className="text-md font-bold  text-color3 ml-5">
              Profile Links:
            </h1>
            <p className="flex ml-5 w-3/4 h-0.5 text-center bg-color1 rounded-md mb-5" />
            <a
              className="text-sm text-custom_black ml-5 hover:text-color3"
              href="/app/profile"
            >
              LinkedIn
            </a>
            <a
              className="text-sm text-custom_black ml-5 hover:text-color3"
              href="/app/profile"
            >
              GitHub
            </a>
            <a
              className="text-sm text-custom_black ml-5 hover:text-color3"
              href="/app/profile"
            >
              Google Scholar
            </a>
            <a
              className="text-sm text-custom_black ml-5 hover:text-color3"
              href="/app/profile"
            >
              ResearchGate
            </a>
<br></br>

            <h1 className="text-md font-bold  text-color3 ml-5">
              Skills
            </h1>
            <p className="flex ml-5 w-3/4 h-0.5 text-center bg-color1 rounded-md mb-5" />
           
            <h1 className="text-sm text-custom_black ml-5">
            <ul>
        {listArray.map((item, index) => (
          <li key={index}><span style={{ marginRight: '5px' }}>&bull;</span>{item}</li>
        ))}
        </ul>
                  </h1>
          </div>

          <div className="flex w-4/5 flex-col pt-10">
            <h1 className="text-2xl font-semibold text-color3" id="name">
              {userData
                ? userData[0]?.firstName.charAt(0).toUpperCase() +
                  userData[0].firstName.slice(1) +
                  " " +
                  userData[0]?.lastName
                : null}
            </h1>

            <h1 className="text-base text-custom_balck mt-2" id="City">
              {userData[0]?.city}
              <span>, </span>
              <span id="Country">{userData[0]?.state}</span>
            </h1>
            <p className="text-base text-custom_black pb-2">
              {userData ? userData[0]?.intro : null}
            </p>
            <div className="flex w-full h-full flex-col pt-2">
              <h1 className="text-2xl font-semibold text-color3">About</h1>
              <p className="flex self-center w-full h-0.5 text-center bg-color1 rounded-md mb-5" />
              <div className="flex w-auto">
                <div className="flex w-1/2 flex-col">
                  <h1 className="text-md font-semibold text-color3 pb-3">
                    Qualification
                  </h1>
                  <h1 className="text-md font-semibold text-color3 pb-3">
                    Organization
                  </h1>
                </div>
                <div className="flex w-1/2 flex-col">
                  <h1
                    className="text-md font-main text-custom_balck pb-3"
                    id="organization"
                  >
                    {userData[0]?.graduationDegree}
                  </h1>
                  <h1
                    className="text-md font-main text-custom_balck pb-3"
                    id="qualification"
                  >
                    {userData[0]?.graduationCollge}
                  </h1>
                </div>
              </div>
            </div>

            {/* experience */}

            <div className="flex w-full h-full flex-col pt-2">
              <h1 className="text-2xl font-semibold text-color3">Experience</h1>
              <p className="flex self-center w-full h-0.5 text-center bg-color1 rounded-md mb-5" />
              <div className="flex w-auto">
                {experiences &&
                  experiences.map((experience, index) => (
                    <div key={index}>
                      <p className="text-sm text-md text-color3 ml-5">
                        {experience.startDate} -{" "}
                        {experience.endDate || "Present"}:{" "}
                        {experience.organization}
                      </p>
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() =>
                          requestDelete(experience, handleDeleteExperience)
                        }
                      />
                    </div>
                  ))}

                {experienceInputs && (
                  <>
                    <form onSubmit={handleSubmitExperience}>
                      <div className="flex items-center space-x-4">
                        <div>
                          <p className="text-xs font-semibold text-custom_black ml-5">
                            Start Date:
                          </p>
                          <input
                            className="inline-flex items-center rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                            type="date"
                            name="startDate"
                            required
                          />
                        </div>

                        <div>
                          <p className="text-xs font-semibold text-custom_black ml-5">
                            End Date:
                          </p>
                          <input
                            className="inline-flex items-center rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                            type="date"
                            name="endDate"
                          />
                        </div>
                      </div>

                      <div className="flex items-center py-3 space-x-4">
                        <div>
                          <p className="text-xs font-semibold text-custom_black ml-5">
                            Organization:
                          </p>
                          <input
                            className="inline-flex items-center rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                            type="text"
                            name="organization"
                            placeholder="Name of Organization"
                            required
                          />
                        </div>

                        <div>
                          <p className="text-xs font-semibold text-custom_black ml-5">
                            position:
                          </p>
                          <input
                            className="inline-flex items-center rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                            type="text"
                            name="position"
                            placeholder="Name of position"
                            required
                          />
                        </div>
                      </div>

                      <button
                        className="inline-flex font-main items-centre mt-1 ml-16 pl-3 text-xs bg-color3 text-white rounded h-4 w-20 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </>
                )}

                {!experienceInputs ? (
                  <button
                    className="inline-flex justify-center rounded-md border p-2 text-sm font-medium text-white bg-color3 focus:ring-4 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
                    onClick={() => setExperienceInputs(true)}
                  >
                    Add experience
                  </button>
                ) : (
                  <button
                    className="inline-flex font-main items-centre mt-10 ml-16 px-5 py-0 text-sm bg-white text-black rounded h-5 w-20 focus:ring-blue-200 border border-custom_grey hover:bg-custom_grey transition duration-150 ease-out hover:ease-in"
                    onClick={() => setExperienceInputs(false)}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>

            {/* cerificates */}

            <div className="flex w-full h-full flex-col pt-2">
              <h1 className="text-2xl font-semibold text-color3">
                Certificates
              </h1>
              <p className="flex self-center w-full h-0.5 text-center bg-color1 rounded-md mb-5" />
              <div className="flex w-auto">
                {certificateData &&
                  certificateData.map((certificate, index) => (
                    <div key={index}>
                      <a
                        className="text-sm text-md text-color3 ml-5"
                        href={certificate.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {certificate.name}
                      </a>
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() =>
                          requestDelete(certificate, handleDeleteCertificate)
                        }
                      />
                    </div>
                  ))}

                {showCertificateInputFields && (
                  <>
                    <form onSubmit={handleSubmitCertificate}>
                      <input
                        className="inline-flex items-centre rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                        type="text"
                        name="certificateName"
                        placeholder="Name of Cerificate"
                      />

                      <input
                        className="inline-flex items-centre rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 ml-5 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                        type="text"
                        name="certificateUrl"
                        placeholder="Verification Link / Certificate ID"
                      />

                      <button
                        className="inline-flex font-main items-centre mt-1 ml-16 pl-3 text-xs bg-color3 text-white rounded h-4 w-20 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </>
                )}

                {!showCertificateInputFields ? (
                  <button
                    className="inline-flex justify-center rounded-md border p-2 text-sm font-medium text-white bg-color3 focus:ring-4 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
                    onClick={() => setShowCertificateInputFields(true)}
                  >
                    Add Certificate
                  </button>
                ) : (
                  <button
                    className="inline-flex justify-center rounded border px-5 py-0 border-custom_grey h-5 w-20 text-sm font-main text-black bg-white  hover:bg-custom_grey transition duration-150 ease-out hover:ease-in"
                    onClick={() => setShowCertificateInputFields(false)}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>

            {/* patents */}

            <div className="flex w-full h-full flex-col pt-2">
              <h1 className="text-2xl font-semibold text-color3">Patents</h1>
              <p className="flex self-center w-full h-0.5 text-center bg-color1 rounded-md mb-5" />
              <div className="flex w-auto">
                {patentData &&
                  patentData.map((patent, index) => (
                    <div key={index}>
                      {patent.url ? (
                        <a
                          className="text-sm text-md text-color3 ml-5"
                          href={patent.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {patent.name}
                        </a>
                      ) : (
                        <h1 className="text-sm text-md text-color3 ml-5">
                          {patent.name}
                        </h1>
                      )}
                      <p>{patent.id}</p>
                      <p>{patent.year}</p>
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() => {
                          requestDelete(patent, handleDeletePatent);
                        }}
                      />
                    </div>
                  ))}

                {showConfirmation && (
                  <ConfirmationOverlay
                    item={itemToDelete}
                    onConfirm={() => handleDelete(itemToDelete)}
                    onCancel={() => setShowConfirmation(false)}
                  />
                )}

                {showPatentInputFields && (
                  <>
                    <form onSubmit={handleSubmitPatent}>
                      <input
                        className="inline-flex items-centre rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                        type="text"
                        name="patentName"
                        placeholder="Name of Patent"
                      />

                      <input
                        className="inline-flex items-centre rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4  ml-5 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                        type="text"
                        name="patentID"
                        placeholder="Patent ID"
                      />

                      <input
                        className="inline-flex items-centre rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                        type="number"
                        name="publishYear"
                        placeholder="Year Of Publish"
                      />

                      <input
                        className="inline-flex items-centre rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 ml-5 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                        type="text"
                        name="patentURL"
                        placeholder="URL of patent (Optional)"
                      />

                      <button
                        className="inline-flex font-main items-centre mt-1 ml-16 pl-3 text-xs bg-color3 text-white rounded h-4 w-20 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </>
                )}

                {!showPatentInputFields ? (
                  <button
                    className="inline-flex justify-center rounded-md border p-2 text-sm font-medium text-white bg-color3 focus:ring-4 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
                    onClick={() => setShowPatentInputFields(true)}
                  >
                    Add Patent
                  </button>
                ) : (
                  <button
                    className="inline-flex justify-center rounded border px-5 py-0 border-custom_grey mt-3 h-5 w-20 text-sm font-main text-black bg-white  hover:bg-custom_grey transition duration-150 ease-out hover:ease-in"
                    onClick={() => setShowPatentInputFields(false)}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>

            <div className="flex w-full h-full flex-col pt-2">
              <h1 className="text-2xl font-semibold text-color3">
                Research Works
              </h1>
              <p className="flex self-center w-full h-0.5 text-center bg-color1 rounded-md mb-5" />
              {papers &&
                papers.map((paper) => (
                  <>
                    <a
                      className="flex items-center text-sm text-md text-color3 ml-5 hover:text-custom_hov"
                      href={paper.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {paper.name}
                    </a>
                  
                    <DeleteIcon
                      className="hover:bg-custom_grey ml-28  cursor-pointer"
                      onClick={() =>
                        requestDelete(paper.name, handleDeletePaper)
                      }
                    />
                  </>
                ))}

              {paperInputs && (
                <>
                  <form onSubmit={handleSubmitPaper}>
                    <div className="flex flex-col items-start">
                      <div className="flex items-center">
                        <div className="flex flex-col">
                          <p className="text-xs font-semibold text-custom_black ml-5">
                            Published in:
                          </p>
                          <select
                            className="inline-flex items-center rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4  px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                            name="dropdown1"
                          >
                            <option value="">Select</option>
                            <option value="Conference">Conference</option>
                            <option value="Journal">Journal</option>
                          </select>
                        </div>

                        <div className="flex flex-col ml-5 ">
                          <p className="text-xs font-semibold ml-5 text-custom_black">
                            Index:
                          </p>
                          <select
                            className="inline-flex items-center rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                            name="dropdown2"
                          >
                            <option value="option1">Others</option>
                            <option value="option2">Scopus</option>
                            <option value="option3">Web of Science</option>
                            <option value="option3">SCI</option>
                            <option value="option3">UGC</option>
                            <option value="option3">SCIE</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex items-center mt-1 ">
                        <input
                          className="inline-flex items-centre rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                          type="text"
                          name="name"
                          placeholder="Name of Paper"
                        />

                        <input
                          className="inline-flex items-centre rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 ml-5 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                          type="text"
                          name="url"
                          placeholder="Link of Paper"
                        />

                        
                      </div>


                      <div className="py-3">
                          <p className="text-xs font-semibold text-custom_black ml-5">
                            Date of publication:
                          </p>
                          <input
                            className="inline-flex items-center rounded-sm focus:outline-none focus:border-color4 focus:border-2 border border-color4 px-3 w-32 font-semibold h-5 text-xs text-custom_black"
                            type="date"
                            name="datePub"
                            required
                          />
                        </div>


                    </div>

                    <button
                          className="inline-flex font-main items-centre mt-1 ml-16 pl-3 text-xs bg-color3 text-white rounded h-4 w-20 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
                          type="submit"
                        >
                          Submit
                        </button>
                  </form>
                </>
              )}
              {/* setPaperInputs(true)} */}

              {!paperInputs ? (
                <button style={{ width: '88px' }}
                className="inline-flex justify-center rounded-md border p-2 text-sm font-medium text-white bg-color3 focus:ring-4 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
                onClick={() => setPaperInputs(true)}
              >
                Add Paper
              </button>
              ) : (
                <button
                className="inline-flex justify-center rounded border px-5 py-0 border-custom_grey mb-5  h-5 w-20 text-sm font-main text-black bg-white  hover:bg-custom_grey -mt-14 ml-auto transition duration-150 ease-out hover:ease-in"
                  onClick={() => setPaperInputs(false)}
                >
                  Cancel
                </button>
              )}
            </div>

            <div className="flex justify-end mt-10 gap-4 items-center w-full mr-auto">
              <button
                onClick={handleProfileEdit}
                className="inline-flex justify-center rounded-md border p-2 text-sm font-medium text-white bg-color3 focus:ring-4 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
              >
                Edit Profile
              </button>
              <button
                onClick={generatePdfDocument}
                className="inline-flex justify-center rounded-md border p-2 text-sm font-medium text-white bg-color3 focus:ring-4 focus:ring-blue-200 hover:bg-custom_hov transition duration-150 ease-out hover:ease-in"
              >
                Profile Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
