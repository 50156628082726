import React, { useState } from 'react';
import MyConnectionsPage from "./MyConnections"
import PendingInvitations from "./PendingInvitations"
import SendConnectionRequest from "./SendR"

const Connect = () => {
  const [toggle, setToggle] = useState(1);

  const handleToggle = (value) => {
    setToggle(value);
  };

  return (
    <>
    <p className="flex text-2xl font-semibold justify-center items-center space-x-4  mt-8">My Network</p>
      <div className="flex justify-center items-center space-x-4 mb-4 mt-8">
        
      <button
        className={`${
          toggle === 1 ? 'bg-color3 text-white' : 'bg-gray-300 text-black'
        } px-4 py-2 rounded`}
        onClick={() => handleToggle(1)}
      >
        Connections
      </button>
      <button
        className={`${
          toggle === 2 ? 'bg-color3 text-white' : 'bg-gray-300 text-black'
        } px-4 py-2 rounded`}
        onClick={() => handleToggle(2)}
      >
        Pending
      </button>
      <button
        className={`${
          toggle === 3 ? 'bg-color3 text-white' : 'bg-gray-300 text-black'
        } px-4 py-2 rounded`}
        onClick={() => handleToggle(3)}
      >
        Requested
      </button>
    </div>
    <div className="mt-4 mx-auto">
      {toggle === 1 && <MyConnectionsPage />}
      {toggle === 2 && <PendingInvitations />}
      {toggle === 3 && <SendConnectionRequest />}
    </div>
  </>
  );
};

export default Connect;