import React, { useEffect, useState } from "react";
import axios from "axios";
// import ConnectionItem from "./ConnectionItem";

const MyConnectionsPage = () => {
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);

  const dummyUserList = [
    {
      id: 1,
      profilePic: 'https://example.com/profile-pic1.jpg',
      name: 'John Doe',
      email: 'john.doe@example.com',
      connectionStatus: 'pending',
    },
    {
      id: 2,
      profilePic: 'https://example.com/profile-pic2.jpg',
      name: 'Jane Smith',
      email: 'jane.smith@example.com',
      connectionStatus: 'requested',
    },
    {
      id: 3,
      profilePic: 'https://example.com/profile-pic3.jpg',
      name: 'Bob Johnson',
      email: 'bob.johnson@example.com',
      connectionStatus: 'connected',
    },
    {
      id: 4,
      profilePic: 'https://example.com/profile-pic4.jpg',
      name: 'Alice Williams',
      email: 'alice.williams@example.com',
      connectionStatus: 'connected',
    },
    {
      id: 5,
      profilePic: 'https://example.com/profile-pic5.jpg',
      name: 'Charlie Brown',
      email: 'charlie.brown@example.com',
      connectionStatus: 'pending',
    },
  ];

  // useEffect(() => {
  //   const fetchConnections = async () => {
  //     try {
  //       const response = await axios.get("/api/connections/my");
  //       setConnections(response.data);
  //     } catch (error) {
  //       console.error("Error fetching connections:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchConnections();
  // }, []);

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        // const response = await axios.get("/api/connections/my");
        setConnections(dummyUserList);
      } catch (error) {
        console.error("Error fetching connections:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchConnections();
  }, []);

  return (
    <div>
      <h1>My Connections</h1>
      {/* {loading ? (
        <div>Loading...</div>
      ) : connections.length > 0 ? (
        <ul>
          {connections.map((connection) => (
            <ConnectionItem key={connection.id} connection={connection} />
          ))}
        </ul>
      ) : (
        <div>No connections found</div>
      )} */}
    </div>
  );
};

export default MyConnectionsPage;
