import React, { useState } from "react";
import { ChatEngine, getOrCreateChat } from "react-chat-engine";

const DirectChatPage = () => {
  const [username, setUsername] = useState("");
  const [showChat, setShowChat] = useState(false);//eslint-disable-line

  function createDirectChat(creds) {
    getOrCreateChat(
      creds,
      { is_direct_chat: true, usernames: [username] },
      () => setUsername("")
    );
  }

  function renderChatForm(creds) {
    return (
      <div>
        <div>
          <input
            className="border-b border-t border-r border-l mt-1 rounded border-black"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button
            className="bg-orange-400 hover:bg-orange-500 font-bold rounded mx-2"
            onClick={() => createDirectChat(creds)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {showChat ? (
        <ChatEngine
          height="100vh"
          userName="Abhishek"
          userSecret="Abhishek"
          projectID="a20aaba9-975f-4491-9a14-28fbf4241993"
          renderNewChatForm={(creds) => renderChatForm(creds)}
        />
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <p className="text-center text-xl">
            This feature is yet to come. Check back soon!
          </p>
        </div>
      )}
    </div>
  );
};

export default DirectChatPage;
