import React from "react";
import UserList from "./UserList";

export default function SearchPage() {
  return (
    <div className="container mt-3">
      <UserList />
    </div>
  );
}
